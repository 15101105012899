import React from "react"
// import Pixel from "../components/pixel"

import { Helmet } from "react-helmet"

const SurveyPage = () => (
  <meta
    http-equiv="Refresh"
    content="0; url='https://hello776268.typeform.com/to/wlCIHUWY'"
  />
)

export default SurveyPage
